// import React from 'react';
import styled from 'styled-components';
import Link from '../Link';

const GhostLink = styled(Link)`
  display: inline-block;
  position: relative;
  padding: 0.75rem 1rem;
  border: 1px solid ${props => props.theme.colors.text};
  color: ${props => props.theme.colors.text};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  text-align: center;
  text-decoration: none;
  transition: ${props => props.theme.transitions.default};
  z-index: 0;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${props => props.theme.colors.text};
    transition: ${props => props.theme.transitions.default};
    transform-origin: 0 0;
    transform: scale(0, 1);
    z-index: -1;
  }

  &:focus,
  &:hover {
    color: #fff;

    &:after {
      transform: scale(1, 1);
    }
  }
`;

export default GhostLink;
